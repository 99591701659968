















































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import TooltipView from '@/components/common/TooltipView.vue'
import {
  ACTION_POST_PATIENT_DATA_PROVIDER,
  GETTER_PATIENT_DATA_PROVIDERS
} from '@/application/store/modules/PatientDataProviderModule'
import { buildQualifiedStoreMethodPath, NAMESPACE_PATIENT_DATA_PROVIDERS } from '@/application/store/RootStore'

@Component({
  components: {
    Tooltip: TooltipView
  }
})
export default class AddPatientDataProviderView extends BaseModel {
  @Action(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, ACTION_POST_PATIENT_DATA_PROVIDER))
  postPatientDataProvider!: (patientDataProvider: PatientDataProvider) =>
    Promise<PatientDataProvider>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, GETTER_PATIENT_DATA_PROVIDERS))
  patientDataProviders!: Array<PatientDataProvider>

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  patientDataProvider = new PatientDataProvider()

  constructor () {
    super('patient-data-provider')
  }

  save (): void {
    if (this.form.validate()) {
      this.postPatientDataProvider(this.patientDataProvider)
        .then(result => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.isDialogOpen = false
    this.form.reset()
  }

  ruleValidateDuplicate (input: string): boolean | string {
    if (!input || !this.patientDataProviders) {
      return false
    }
    const duplicates: Array<PatientDataProvider> =
      this.patientDataProviders.filter(provider => provider.name!.trim().toLowerCase() === input.trim().toLowerCase())
    return duplicates.length === 0 ||
      this.getLangText('add.duplicate')
  }
}
