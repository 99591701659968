



























































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import TooltipView from '@/components/common/TooltipView.vue'
import Hospital from '@/application/model/Hospital'
import {
  buildQualifiedStoreMethodPath,
  NAMESPACE_HOSPITALS,
  NAMESPACE_PATIENT_DATA_PROVIDERS
} from '@/application/store/RootStore'
import { ACTION_POST_HOSPITAL, GETTER_HOSPITALS } from '@/application/store/modules/HospitalsModule'
import { GETTER_PATIENT_DATA_PROVIDERS } from '@/application/store/modules/PatientDataProviderModule'

@Component({
  components: {
    Tooltip: TooltipView
  }
})
export default class AddHospitalView extends BaseModel {
  @Action(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_POST_HOSPITAL))
  postHospital!: (hospital: Hospital) =>
    Promise<Hospital>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, GETTER_PATIENT_DATA_PROVIDERS))
  patientDataProviders!: Array<PatientDataProvider>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, GETTER_HOSPITALS))
  hospitals!: Array<Hospital>

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  hospital = new Hospital()

  constructor () {
    super('hospital')
  }

  save (): void {
    if (this.form.validate()) {
      this.postHospital(this.hospital)
        .then(result => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.isDialogOpen = false
    this.form.reset()
  }

  ruleIsNotDuplicate (input: string) {
    if (!input || !this.hospitals) {
      return false
    }
    const duplicates: Array<Hospital> =
      this.hospitals.filter(hospital => hospital.name!.trim().toLowerCase() === input.trim().toLowerCase())
    return duplicates.length === 0 ||
      this.getLangText('add.duplicate')
  }
}
