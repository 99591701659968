






















import { Component } from 'vue-property-decorator'
import BaseModel from '../../../application/model/BaseModel'
import { Getter } from 'vuex-class'
import { DataTableHeader } from 'vuetify'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import AddPatientDataProvider from '@/components/admin/patientdataproviders/AddPatientDataProviderView.vue'
import { buildQualifiedStoreMethodPath, NAMESPACE_PATIENT_DATA_PROVIDERS } from '@/application/store/RootStore'
import { GETTER_PATIENT_DATA_PROVIDERS } from '@/application/store/modules/PatientDataProviderModule'
import EditPatientDataProviderView from '@/components/admin/patientdataproviders/EditPatientDataProviderView.vue'

@Component({
  components: { EditPatientDataProviderView, AddPatientDataProvider }
})
export default class PatientDataProvidersView extends BaseModel {
  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, GETTER_PATIENT_DATA_PROVIDERS))
  patientDataProviders!: Array<PatientDataProvider>

  constructor () {
    super('patient-data-provider')
  }

  get tableHeader (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('id'),
        value: 'id'
      },
      {
        text: this.getLangText('name'),
        value: 'name'
      },
      {
        text: '',
        value: 'edit'
      }
    ]
  }
}
