







import { Component } from 'vue-property-decorator'
import BaseModel from '../../application/model/BaseModel'
import PatientDataProvidersView from '@/components/admin/patientdataproviders/PatientDataProvidersView.vue'
import HospitalsView from '@/components/admin/hospitals/HospitalsView.vue'
import { Action } from 'vuex-class'
import {
  buildQualifiedStoreMethodPath,
  NAMESPACE_HOSPITALS,
  NAMESPACE_PATIENT_DATA_PROVIDERS
} from '@/application/store/RootStore'
import { ACTION_FETCH_PATIENT_DATA_PROVIDERS } from '@/application/store/modules/PatientDataProviderModule'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import { ACTION_FETCH_HOSPITALS } from '@/application/store/modules/HospitalsModule'

@Component({
  components: {
    PatientDataProviders: PatientDataProvidersView,
    Hospitals: HospitalsView
  }
})
export default class AdminPanel extends BaseModel {
  @Action(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, ACTION_FETCH_PATIENT_DATA_PROVIDERS))
  fetchPatientDataProviders!: () => Promise<Array<PatientDataProvider>>

  @Action(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_FETCH_HOSPITALS))
  fetchHospitals!: () => Promise<Array<PatientDataProvider>>

  constructor () {
    super('admin')
  }

  async mounted () {
    await this.fetchPatientDataProviders()
    await this.fetchHospitals()
  }
}
