






















import { Component } from 'vue-property-decorator'
import BaseModel from '../../../application/model/BaseModel'
import { Getter } from 'vuex-class'
import { DataTableHeader } from 'vuetify'
import AddHospitalView from '@/components/admin/hospitals/AddHospitalView.vue'
import Hospital from '@/application/model/Hospital'
import { GETTER_HOSPITALS } from '@/application/store/modules/HospitalsModule'
import { buildQualifiedStoreMethodPath, NAMESPACE_HOSPITALS } from '@/application/store/RootStore'
import EditHospitalView from '@/components/admin/hospitals/EditHospitalView.vue'

@Component({
  components: { EditHospitalView, AddHospital: AddHospitalView }
})
export default class HospitalsView extends BaseModel {
  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, GETTER_HOSPITALS))
  hospitals!: Array<Hospital>

  constructor () {
    super('hospital')
  }

  get tableHeader (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('id'),
        value: 'id'
      },
      {
        text: this.getLangText('name'),
        value: 'name'
      },
      {
        text: this.getLangTextWithoutPrefix('patient-data-provider.id'),
        value: 'patientDataProvider.id'
      },
      {
        text: this.getLangTextWithoutPrefix('patient-data-provider.name'),
        value: 'patientDataProvider.name'
      },
      {
        text: '',
        value: 'edit'
      }
    ]
  }
}
