











































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import VitalsSource from '@/application/model/VitalsSource'
import Hospital from '@/application/model/Hospital'
import { buildQualifiedStoreMethodPath, NAMESPACE_HOSPITALS } from '@/application/store/RootStore'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import { ACTION_PUT_HOSPITAL } from '@/application/store/modules/HospitalsModule'

@Component
export default class EditHospitalView extends BaseModel {
  @Prop({ required: true })
  hospital!: Hospital

  newHospital: VitalsSource = new VitalsSource()

  @Action(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_PUT_HOSPITAL))
  putHospital!: (patientDataProvider: PatientDataProvider) => Promise<PatientDataProvider>

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  constructor () {
    super('hospital')
  }

  async mounted (): Promise<void> {
    this.newHospital = JSON.parse(JSON.stringify(this.hospital))
  }

  send (): void {
    if (this.form.validate()) {
      this.putHospital(this.newHospital)
        .then(() => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.isDialogOpen = false
  }
}
