











































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import VitalsSource from '@/application/model/VitalsSource'
import { buildQualifiedStoreMethodPath, NAMESPACE_PATIENT_DATA_PROVIDERS } from '@/application/store/RootStore'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import { ACTION_PUT_PATIENT_DATA_PROVIDER } from '@/application/store/modules/PatientDataProviderModule'

@Component
export default class EditPatientDataProviderView extends BaseModel {
  @Prop({ required: true })
  patientDataProvider!: PatientDataProvider

  newPatientDataProvider: VitalsSource = new VitalsSource()

  @Action(buildQualifiedStoreMethodPath(NAMESPACE_PATIENT_DATA_PROVIDERS, ACTION_PUT_PATIENT_DATA_PROVIDER))
  putPatientDataProvider!: (patientDataProvider: PatientDataProvider) => Promise<PatientDataProvider>

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  constructor () {
    super('patient-data-provider')
  }

  async mounted (): Promise<void> {
    this.newPatientDataProvider = JSON.parse(JSON.stringify(this.patientDataProvider))
  }

  send (): void {
    if (this.form.validate()) {
      this.putPatientDataProvider(this.newPatientDataProvider)
        .then(() => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.isDialogOpen = false
  }
}
